<template>
        <section class="permission">
            <!--列表-->
            <el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
                <el-table-column prop="role_name" label="用户名" width="150">
                </el-table-column>
                <el-table-column prop="remark" label="店名">
                </el-table-column>            
                <el-table-column prop="role_name" label="域名">
                </el-table-column>
                <el-table-column prop="create_time" label="店铺到期时间" sortable width="200">
                </el-table-column>
                <el-table-column prop="create_time" label="域名到期时间" sortable width="200">
                </el-table-column>
                <el-table-column label="操作" width="150" align="center">
                    <template slot-scope="scope">
                        <template>
                            <el-button type="primary" plain size="small" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit" title="角色修改">域名续费确认</el-button>
                        </template>
                    </template>
                </el-table-column>
            </el-table>    
            <!--工具条-->
            <el-col :span="24" class="toolbar">
                <!--分页-->
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :page-sizes="pagesizes"
                        :page-size="pagesize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </el-col>
    
        </section>
    </template>
    
    <script>
        import { getAdminList, stateAdmin} from '../../api/api';
        export default {
            data() {
                return {
                    filters: {
                        keyword: ''
                    },
                    listData: [], //管理员列表数据
                    //分页
                    total: 0,
                    page: 1,
                    pagesize: 10,//每页条数
                    pagesizes: [10, 20, 50, 100],//可选条数
                    listLoading: false,
                    sels: [],//列表选中列
                }
            },
            methods: {
                //分页
                handleSizeChange(val) {
                    this.pagesize = val;
                    this.getList();
                },
                handleCurrentChange(val) {
                    this.page = val;
                    this.getList();
                },
                //获取用户列表
                getList(val) {
                    if(val == '0') this.page = 1
                    let para = {
                        page: this.page,
                        keyword: this.filters.keyword,
                        pagesize: this.pagesize	//分页条数
                    };
                    this.listLoading = true;
                    getAdminList(para).then((res) => {
                        if(res.code == 1){
                            this.total = res.data.count;
                            this.listData = res.data.data;
                            this.listLoading = false;
                        }else {
                            this.$notify({
                                title: '错误',
                                message: res.msg,
                                type: 'error'
                            });
                        }
                    });
                },
            },
            mounted() {
                //获取列表数据
                //this.getList();
            }
        }
    
    </script>
    
    <style lang="scss">
        @import "../../styles/permission.scss";
    </style>